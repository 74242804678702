import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import PropTypes from "prop-types";

const Image = ({image, className}) => {
    return <GatsbyImage
        className={className}
        image={image.childImageSharp.gatsbyImageData}
    />
}

Image.propTypes = {
    image: PropTypes.shape({
        childImageSharp: PropTypes.shape({
            gatsbyImageData: PropTypes.object.isRequired,
        }).isRequired,
    }).isRequired,
}

export default Image
