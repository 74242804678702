import React, {useState} from "react"
import PropTypes from "prop-types";
import styled from "styled-components"
import Image from "../gabarits/image";
import { Formik, Form, Field, ErrorMessage } from 'formik';

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  & > * {
    width: 100%;
    z-index: 1;
  }
  
  h2 {
    margin: 100px auto;
  }
`

const StyledImage = styled(Image)`
    position: absolute !important;
    height: 100%;
    
    img {
      object-fit: cover;
      filter: brightness(50%);
    }
`

const StyledForm = styled(Form)`
  display: flex;
  align-content: center;
  flex-wrap: wrap;
  position: relative;
  padding: 0 15px;
  max-width: 600px;
  
  .status-message {
    display: block;
    width: 100%;
    margin-top: -45px;
    text-align: center;
  }
`

const Label = styled.label`
  display: block;
  font-size: 14px;
  width: 100%;
  
  span {
    display: inline-block;
    
    &:after {
      display: inline-block;
      content: "*";
      margin-left: 5px;
    }
    
    &.error {
      color: #ff5a5a;
      margin-left: 20px;
      font-weight: bold;

      &:after {
        content: " -";
      }

      &:before {
        content: "- ";
      }
    }
  }
  
  input, textarea {
    display: block;
    margin: 10px 0 20px;
    padding: 10px;
    width: 100%;
    box-sizing: border-box;
    height: 3em;
   }
   
   textarea {
     max-width: 100%;
     min-width: 100%;
     min-height: 12em;
   }
`

const Button = styled.button`
  padding: 0.7em 1.5em;
  border: 2px solid #FFFFFF;
  color: #FFFFFF;
  text-transform: uppercase;
  background: none;
  font-size: 14px;
  margin: 60px auto;
  cursor: pointer;
  position: relative;
  transition: all .3s;
  
  &:hover {
      background-color: #e7d0ba;
      color: #000;
  }

  &.loading {
    padding-right: 2.5em !important;
  }
  
  @keyframes spin {
    from {transform:rotate(0deg);}
    to {transform:rotate(360deg);}
  }

  .status-loader {
    display: inline-block;
    position: absolute;
    width: 1em;
    height: 1em;
    margin: -0.5em;
    top: 50%;
    left: auto;
    right: 1.25em;
    box-sizing: content-box;
    animation: spin 1s infinite linear;

    &::after {
      border-radius: 50%;
      border: .3em solid currentColor;
      border-left-color: transparent;
      content: " ";
      display: block;
      width: 2em;
      height: 2em;
      box-sizing: border-box;
      transform-origin: 0 0;
      transform: translateZ(0) scale(0.5);
      backface-visibility: hidden;
    }
  }
`

const FormulaireDeContact = ({anchor, image, title}) => {
    const [status, setStatus] = useState("idle");

    return <Wrapper id={anchor}>
        <StyledImage image={image}/>
        <h2>{title}</h2>
        <Formik
            initialValues={{name: "", _subject: "", _replyto: "", message: ""}}

            validate={values => {
                const errors = {};

                if (!values.name.trim()) {
                    errors.name = 'Requis';
                }

                if (!values._subject.trim()) {
                    errors._subject = 'Requis';
                }

                if (!values.message.trim()) {
                    errors.message = 'Requis';
                }

                if (!values._replyto) {
                    errors._replyto = 'Requis';
                } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values._replyto)) {
                    errors._replyto = 'Adresse email invalide';
                }

                return errors;
            }}

            onSubmit={(values, {setSubmitting, resetForm}) => {
                const data = new FormData();

                for (const key in values) {
                    data.append(key, values[key]);
                }

                setStatus("loading");

                fetch("https://formspree.io/f/mayldzoo", {
                    method: "POST",
                    body: data,
                    redirect: "manual"
                }).then((response) => {
                    if (response.ok || response.type === "opaqueredirect") {
                        setStatus("ok");
                        resetForm();
                    } else {
                        setStatus("ko");
                    }

                    setSubmitting(false);
                })
            }}
        >
            {({isSubmitting}) => (
                <StyledForm method="POST" id={anchor}>
                    <Label>
                        <span>Nom</span>
                        <ErrorMessage name="name" component="span" className="error"/>
                        <Field type="text" name="name" required/>
                    </Label>

                    <Label>
                        <span>Email</span>
                        <ErrorMessage name="_replyto" component="span" className="error"/>
                        <Field type="email" name="_replyto" required/>
                    </Label>

                    <Label>
                        <span>Sujet</span>
                        <ErrorMessage name="_subject" component="span" className="error"/>
                        <Field type="text" name="_subject" required/>
                    </Label>

                    <Label>
                        <span>Message</span>
                        <ErrorMessage name="message" component="span" className="error"/>
                        <Field type="text" name="message" component="textarea" required/>
                    </Label>

                    <Button type="submit" disabled={isSubmitting} className={status}>
                        Envoyer
                        {status === "loading" && <span className="status-loader"/>}
                    </Button>

                    {status === "ok" && <span className="status-message">✅ Message envoyé</span>}
                    {status === "ko" && <span className="status-message">❌ Une erreur est survenue</span>}
                </StyledForm>
            )}
        </Formik>
    </Wrapper>
}

FormulaireDeContact.propTypes = {
    anchor: PropTypes.string,
    title: PropTypes.string.isRequired,
    image: PropTypes.shape({
        childImageSharp: PropTypes.shape({
            gatsbyImageData: PropTypes.object.isRequired,
        }).isRequired,
    }).isRequired,
}

export default FormulaireDeContact;
