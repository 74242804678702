import React from "react"
import PropTypes from "prop-types";
import styled from "styled-components"
import {graphql, useStaticQuery, Link} from "gatsby";
import Image from "../gabarits/image";

const Wrapper = styled.nav`
  ul {
    padding: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;  
    justify-content: space-between; 
    margin: -20px 0 0 0;
  }

  li {
    margin-top: 20px;
    display: block;
    width: 30%;
    text-align: center;
    box-sizing: border-box; 

    h2 {
      font-family: 'Open Sans', sans-serif;
      font-size: 16px;
      text-transform: uppercase;
      color: #e7d0ba;
    }
  }
  
  a {
    display: block;
    color: #ADADAD;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`

const Item = ({title, link, attachment, image}) => {
    const content = (
        <>
            <Image image={image}/>
            <h2>{title}</h2>
        </>
    )

    return (
        <li>
            {attachment
                ? <a href={attachment.publicURL} target="_blank" rel="noreferrer">{content}</a>
                : <Link to={link}>{content}</Link>
            }
        </li>
    )
}

Item.propTypes = {
    title: PropTypes.string.isRequired,
    link: PropTypes.string,
    attachment: PropTypes.shape({
        publicURL: PropTypes.string,
    }),
    image: PropTypes.shape({
        childImageSharp: PropTypes.shape({
            gatsbyImageData: PropTypes.object.isRequired,
        }).isRequired,
    }).isRequired,
}

const IdeoDAges = ({anchor}) => {
    const {items: {edges: items}} = useStaticQuery(graphql`
        {
            items: allFile(
                filter: {sourceInstanceName: {eq: "ideo-d-âges"}}
                sort: {fields: [name], order: ASC}
            ) {
                edges {
                    node {
                        id
                        childIdeoDAgesYaml {
                            title
                            link
                            image {
                                childImageSharp {
                                    gatsbyImageData(layout: CONSTRAINED, width: 300)
                                }
                            }
                            # attachment {
                            #     publicURL
                            # }
                        }
                    }
                }
            }
        }
    `)

    return (
        <Wrapper id={anchor}>
            <ul>
                {items.map(({node: {id, childIdeoDAgesYaml: ideo}}) =>
                    <Item key={id} {...ideo} body={ideo.html} />
                )}
            </ul>
        </Wrapper>
    )
}

IdeoDAges.propTypes = {
    anchor: PropTypes.string,
}

export default IdeoDAges
