import React from "react"
import {graphql, useStaticQuery} from "gatsby";
import styled from "styled-components";
import TexteLibre from "./texte-libre";
import Artiste from "./artiste";
import IdeoDAges from "./ideo-d-ages";
import FormulaireDeContact from "./formulaire-de-contact";

const Wrapper = styled.div`
    text-align: center;
`

const Accueil = () => {
    const {blocks: {edges: items}} = useStaticQuery(graphql`
        {
            blocks: allFile(
                filter: {sourceInstanceName: {eq: "accueil"}}
                sort: {fields: [name], order: ASC}
            ) {
                edges {
                    node {
                        id
                        childMarkdownRemark {
                            html
                            frontmatter {
                                type
                                title
                                anchor
                                image {
                                    childImageSharp {
                                        gatsbyImageData(layout: FULL_WIDTH)
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    `)

    return (
        <Wrapper>
            {items.map(({node: {id, childMarkdownRemark: block}}) => {
                switch (block.frontmatter.type) {
                    case 'free-text':
                        return <TexteLibre theme="block-center" key={id} body={block.html} {...block.frontmatter}/>

                    case 'artist-highlight':
                        return <Artiste key={id} body={block.html} {...block.frontmatter}/>

                    case 'ideo-d-âges':
                        return <IdeoDAges key={id} {...block.frontmatter}/>

                    case 'formulaire-de-contact':
                        return <FormulaireDeContact key={id} {...block.frontmatter}/>

                    default:
                        throw new Error(`Unable to instantiate homepage block of type ${block.frontmatter.type}`)
                }
            })}
        </Wrapper>
    )
}

Accueil.propTypes = {}

export default Accueil
