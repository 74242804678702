import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components"

const Wrapper = styled.div`
  p {
    font-size: 16px
  }
  
  a {
      font-size: 0.9em;
      margin-top: 20px;
      display: inline-block;
      padding: 0.5em 2em;
      border: 2px solid #FFFFFF;
      color: #FFFFFF;
      text-transform: uppercase;
      text-decoration: none;
      
      &:hover {
          background-color: #e7d0ba;
          color: #000;
      }
  }
`

const TexteLibre = ({body, anchor}) => (
    <Wrapper id={anchor} dangerouslySetInnerHTML={{__html: body}} />
)

TexteLibre.propTypes = {
    body: PropTypes.string.isRequired,
    anchor: PropTypes.string,
}

export default TexteLibre;
