/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

import 'normalize.css/normalize.css'

export const onRouteUpdate = ({ location, prevLocation }) => {
    if (location && location.state) {
        location.state.referrer = prevLocation ? prevLocation.pathname : null
    }
}
