import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Image from "../gabarits/image";
import TexteLibre from "./texte-libre";

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  @media screen and (min-width: 800px) {
      flex-wrap: nowrap;
  }

  & > div {
    width: 100%;
    @media screen and (min-width: 800px) {
      width: 50%;
    }
    
    &:last-child {
      background-color: #000000;
      padding: 15px;
      color: #e7d0ba;
      font-size: 14px;      
    }
  }
`

const Artiste = ({title, image, anchor, body}) => (
    <Wrapper id={anchor}>
        <Image image={image}/>
        <div>
            <h2>{title}</h2>
            <TexteLibre body={body}/>
        </div>
    </Wrapper>
)

Artiste.propTypes = {
    title: PropTypes.string.isRequired,
    image: PropTypes.shape({
        childImageSharp: PropTypes.shape({
            gatsbyImageData: PropTypes.object.isRequired,
        }).isRequired,
    }).isRequired,
    anchor: PropTypes.string,
    body: PropTypes.string.isRequired,
}

export default Artiste;
